import React from "react";
import classNames from "classnames";

// interface
import { IVideoPlayer } from "./IVideoPlayer.d";

// assets
import poster from "@/assets/images/landing/landing-banner-poster.webp";
import styles from "@/assets/styles/components/video-player.module.scss";


const VideoPlayer = ({ src, className }: IVideoPlayer) => (
  <div className={classNames(styles["video-player"], "video-player", className)} data-testid="locl-video-player">
    <iframe
      srcDoc={`
          <style>
              html, body { margin: 0; padding: 0; width: 100%; height: 100%; overflow: hidden; }
              video { width: 100%; height: 100%; object-fit: cover; pointer-events: none; }
          </style>
          <video
            preload="metadata"
            poster="${poster.src}"
            playsinline
            muted
            autoplay
            loop
          >
            <source src="${src}" type="video/mp4">
          </video>
      `}
      allowFullScreen={true}
    />
  </div>
);

export default VideoPlayer;

import { useSyncExternalStore } from "react";

// helpers
import { customDebounce } from "@/helpers/customDebounce";
import { queryClient } from "@/api/query/reactQuery";


interface IUseWindowDimension { initWidth?: number; }
type TListener = () => void;

let listeners: TListener[] = [];
let dimension = [
  typeof window !== "undefined" ? window.innerWidth : 0,
  typeof window !== "undefined" ? window.innerHeight : 0
];

const subscribe = (listener: TListener) => {
  listeners = [...listeners, listener];

  return () => {
    listeners = listeners.filter(item => item !== listener);
  };
};

typeof window !== "undefined" && window.addEventListener("resize", customDebounce(() => {
  listeners.forEach(notify => {
    dimension = [window.innerWidth, window.innerHeight];

    notify();
  });
}, 350));

const getSnapshot = () => {
  return dimension;
}


export function useDimension(args?: IUseWindowDimension) {
  const viewport = queryClient.getQueryData(["deviceViewport"]);
  const defaultWidth = args?.initWidth || viewport === "desktop" ? 1440 : 520;
  const state = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  return [state[0] || defaultWidth, state[1] || 0];
}

import { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter as nextUseRouter, useParams } from "next/navigation";

const useRouter = () => {
  const { push, replace, ...rest } = nextUseRouter();
  const { lang } = useParams();

  const langPrefix = process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && !process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD ? `/${lang}` : "";

  return {
    ...nextUseRouter,
    push: (href: string, options?: NavigateOptions | undefined) => {
      const newPathName = [
        langPrefix,
        href.replace(langPrefix, ""),
      ].join("");

      if (options) {
        push(newPathName, options);
      } else {
        push(newPathName);
      }
    },
    replace: (href: string, options?: NavigateOptions | undefined) => {
      const newPathName = [
        langPrefix,
        href.replace(langPrefix, ""),
      ].join("");

      
      if (options) {
        replace(newPathName, options);
      } else {
        replace(newPathName);
      }
    },
    ...rest,
  };
}

export default useRouter;

// helpers
import { useFetch } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_URLS from "@/api/routes.constant";

// interfaces
import { IAbstractRespListItem } from "@/types/Global";
import { ICountryResp } from "@/types/api/CountryStateCity";
import { UseQueryInterface } from "@/types/api/queryArgs";

const { countryStateCityUrls } = API_URLS;

const countryStateCity = {
  useCountriesList: () => useFetch<IAbstractRespListItem[]>(countryStateCityUrls.countries),
  useRegionsList: (countryId?: number) => {
    return useFetch<IAbstractRespListItem[]>(
      countryId ? pathToUrl(countryStateCityUrls.regions, { countryId }) : null
    );
  },
  useCitiesList: ({ countryId, regionId }: { countryId?: number, regionId?: number }) => {
    const fetchKey = (countryId && regionId)
      ? pathToUrl(countryStateCityUrls.cities, { countryId, regionId })
      : "";

    return useFetch<IAbstractRespListItem[]>(fetchKey);
  },
  useCountriesFilters: (params: Record<string, unknown>, config?: UseQueryInterface) => {

    return useFetch<ICountryResp[]>(countryStateCityUrls.countriesFilters, params, config);
  },
};

export default countryStateCity;

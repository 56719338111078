export const customDebounce = (mainFunction: (...args: unknown[]) => unknown, delay: number) => {
  // eslint-disable-next-line init-declarations
  let timer: ReturnType<typeof setTimeout>;

  return function (...args: unknown[]) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
};

import React from "react";
import { Tooltip, TooltipProps as AntdTooltipProps } from "antd";
import { HiInformationCircle } from "react-icons/hi";
import classNames from "classnames";

// assets
import styles from "@/assets/styles/components/info-tooltip.module.scss";

function InfoTooltip({ align, overlayClassName, ...props }: AntdTooltipProps) {
  const className = classNames(styles["locl-info-tooltip"], overlayClassName);

  return (
    <Tooltip {...props} align={align || { offset: [0, 5] }} overlayClassName={className}>
      <span className={styles["locl-info-tooltip-icon"]}>
        <HiInformationCircle />
      </span>
    </Tooltip>
  );
}

export default InfoTooltip;

import React, { useState } from "react";
import { Tag as AntdTag } from "antd";
import classNames from "classnames";
import { GoInfo } from "react-icons/go";
import { IoClose } from "react-icons/io5";

// interfaces
import { TagProps } from "./ITag";

// assets
import styles from "../../assets/styles/shared/tag.module.scss";

function Tag(props: TagProps) {
  const {
    className = "",
    fade = true,
    color = "",
    size = "",
    icon = <GoInfo />,
    closeIcon,
    onClose,
    children,
    closable = false,
    ...rest
  } = props;

  const [isVisible, setIsVisible] = useState(true);

  const tagClassName = classNames(
    "locl-tag",
    styles["locl-tag"],
    className,
    styles[`locl-tag_color-${color}`],
    styles[`locl-tag_size-${size}`],
    { [styles["locl-tag_fade"]]: fade }
  );

  if (!isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);

    onClose && onClose();
  };

  return (
    <AntdTag
      {...rest}
      icon={icon}
      className={tagClassName}
      closeIcon={closeIcon || <IoClose className={styles["locl-tag__close-icon"]} />}
      onClose={handleClose}
      closable={closable}
    >
      <span className={styles["locl-tag__text"]}>{children}</span>
    </AntdTag>
  );
}

export default Tag;
